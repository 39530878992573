.clinical-trial-configuration-table {
    border: 0;
    border-collapse: collapse;
    font-size: 1em;
}

.clinical-trial-configuration-table th, .clinical-trial-configuration-table td {
    border: 1px solid white;
    padding: 0.5em 1em;
}

.clinical-trial-configuration-table th input[type="text"],
.clinical-trial-configuration-table td input[type="text"],
.clinical-trial-configuration-table th input[type="number"],
.clinical-trial-configuration-table td input[type="number"] {
    width: 10em;
}

.clinical-trial-configuration-table th input[type="text"],
.clinical-trial-configuration-table td input[type="text"] {
    text-align: center;
}

.clinical-trial-configuration-table th input[type="number"],
.clinical-trial-configuration-table td input[type="number"] {
    text-align: right;
}

.clinical-trial-configuration-table th select {
    max-width: 10em;
}

.clinical-trial-configuration-table input[type="button"] {
    border: 1px solid white;
    width: 1.2em;
    height: 1.2em;
    line-height: .6em;
    cursor: pointer;
}